import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorToaster, successToaster } from "../helpers/utils";
import PasswordInput from "./Inputs/PasswordInput";
const NewPassword = ({ email, setloginModalOpen, modalCloseFunction }) => {
	const [loading, setLoading] = React.useState(false);
	const formik = useFormik({
		initialValues: { verificationCode: "", password: "" },
		validationSchema: Yup.object({
			verificationCode: Yup.string()
				.required("Required")
				.matches(/^\d+$/, "Only numbers accepted."),
			password: Yup.string()
				.required("Required")
				.min(6, "Must be at least 6 characters")
				.matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Must Contain  One Uppercase Character")
				.matches(/[0-9]/, "Must Contain One Number")
		}),
		onSubmit: values => setNewPassword(values)
	});
	const setNewPassword = async ({ password, verificationCode }) => {
		try {
			setLoading(true);
			await Auth.forgotPasswordSubmit(email, verificationCode, password);
			successToaster("Password changed successfully!");
			modalCloseFunction(false);
		} catch (err) {
			setLoading(false);
			let error = err.message || "Something went wrong!";
			errorToaster(error);
		}
	};
	return (
		<Fragment>
			<form onSubmit={formik.handleSubmit}>
				<div className="card signup_card">
					<div className="card-body">
						<div className="container px-5 mt-5">
							<div className="row">
								<div className="col-md-12 mt-2">
									<span className="signup_placeholder">Reset Password</span>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<div className="mb-3">
										<input
											type="text"
											className="form-control"
											placeholder="Vertification Code"
											name="verificationCode"
											onPaste={e => {
												e.preventDefault();
												return false;
											}}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.verificationCode}
										/>
										{formik.errors.verificationCode && (
											<small className="text-danger">
												{formik.errors.verificationCode}
											</small>
										)}
									</div>
									<div className="mb-3">
										<PasswordInput
											name="password"
											className="form-control"
											placeholder="New Password"
											onPaste={e => {
												e.preventDefault();
												return false;
											}}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.password}
										/>
										{formik.errors.password && (
											<small className="text-danger">
												{formik.errors.password}
											</small>
										)}
									</div>
								</div>

								<div className="d-flex justify-content-end">
									{!loading && (
										<button
											type="submit"
											className=" ml-auto btn btn-theme text-end"
										>
											Change Password
										</button>
									)}
									{loading && (
										<div
											className="spinner-border ms-auto  loading-btn"
											role="status"
										></div>
									)}
								</div>
								{/* <div className="col-12 text-center my-4">
									<span className="or_placeholder">
										<span className="or_text"> OR </span>
									</span>
								</div>
								<div className="col-12 text-center">
									<button className="btn m-1 google_btn fb_btn">
										Login with <img src="/img/Facebook.svg" alt="Facebook Logo"/>
									</button>
									<button className="btn m-1 google_btn">
										<span> Login with </span> <img src="/img/google.svg" alt="Google Logo"/>
									</button>
								</div> */}
							</div>
							<div className="col-md-12 text-center my-3 ">
								<span
									className="signup_helper_text"
									onClick={() => {
										setloginModalOpen(true);
										modalCloseFunction(false);
									}}
								>
									Try signing-in again?
									<span className="signup"> Sign-in </span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>

			<style jsx>{`
				.signup_card {
					border-radius: 40px;
					background: url("/img/login.svg") left top no-repeat;
					border: none;
					border-radius: 0;
				}
				.signup_placeholder {
					font-family: Caveat Brush;
					font-size: 72px;
					line-height: 92px;
					color: #fb5850;
					display: block;
				}
				.signup_helper_text {
					display: block;
					color: #572148;
					font-family: Prompt;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					cursor: pointer;
				}
				.signup {
					text-decoration: underline;
					text-decoration-color: rgba(251, 88, 80, 0.25);
					text-decoration-thickness: 0.25rem;
				}

				.forget_password {
					font-family: Caveat Brush;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 23px;
					color: #000000;
				}

				.align_center {
					display: flex;
					align-items: center;
				}

				.or_text {
					color: #572148;
					font-family: Nunito;
					font-size: 13.0286px;
					line-height: 150%;
					background: #dddddd;
					border-radius: 50%;
					padding: 8px;
					top: -15px;
					right: -24px;
				}

				.or_placeholder {
					position: relative;
				}

				.or_hr {
					margin: 0 15%;
					height: 3px;
				}

				.google_btn {
					font-weight: 500;
					font-size: 18px;
					color: rgba(0, 0, 0, 0.54);
				}

				.fb_btn {
					background: #1877f2;
					color: #ffffff;
				}
				input {
					background: #fff !important;
				}
				.form-btn {
					background: transparent !important;
					border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}

				.loading-btn {
					color: #fb5850 !important;
					text-align: rigt !important;
				}
			`}</style>
		</Fragment>
	);
};

export default NewPassword;
